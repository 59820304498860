import React from "react";
import smart_comply_logo from "../../assets/icons/secure.svg";
import { Link } from "react-router-dom";
import CustomLink from "../primitive/CustomLink";
import FrameworkDropdown from "../primitive/dropdown/FrameworkDropdown";
import initial_white_icon from "../../assets/icons/inital_white_arrow.svg";
import hover_white_icon from "../../assets/icons/hover_white_arrow.svg";
import ResourcesDropdown from "../primitive/dropdown/ResourcesDropdown";
import LanguageSwitcher from "../primitive/LanguageSwitcher";
import MobileSidebar from "./MobileSidebar";

// Use translation
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { t } = useTranslation("header");

  return (
    <div className="">
      <div className="container mx-auto">
        <nav className="flex flex-row items-center justify-between py-4">
          <Link to="/">
            <img src={smart_comply_logo} alt="secure" className="w-36 max-md:w-32 h-auto" />
          </Link>
          <div className="hidden xl:flex flex-row items-center space-x-4 text-base">
            <Link to="/">{t("header.home")}</Link>
            <Link to="/features">{t("header.features")}</Link>
            <FrameworkDropdown />
            <Link to="/pricing">{t("header.pricing")}</Link>
            <ResourcesDropdown />
            <Link to="/about-us">{t("header.aboutUs")}</Link>
            <CustomLink
              to="/auth/sign-up"
              title={t("header.started")}
              className="bg-Ebony text-sm md:text-base text-white hover:bg-ShipGrey"
              inital_arrow={initial_white_icon}
              hover_arrow={hover_white_icon}
            />
            <LanguageSwitcher />
          </div>
          <div className="block xl:hidden">
            <MobileSidebar />
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
