export const frameworkData = [
	{
		id: 1,
		title: "frameworksBody.title1",
		link: "/frameworks/iso27001",
		details: "frameworksBody.title1Brief",
	},
	{
		id: 13,
		title: "frameworksBody.title13",
		link: "/frameworks/pcidss",
		details: "frameworksBody.title13Brief",
	},
	{
		id: 12,
		title: "frameworksBody.title12",
		link: "/frameworks/soc2",
		details: "frameworksBody.title12Brief",
	},

	{
		id: 8,
		title: "frameworksBody.title8",
		link: "/frameworks/ndpr",
		details: "frameworksBody.title8Brief",
	},
	{
		id: 'iso22301',
		title: "frameworksBody.title2",
		link: "/frameworks",
		details: "frameworksBody.title2Brief",
	},

	{
		id: "ciidirectives",
		title: "frameworksBody.title5",
		link: "/frameworks",
		details: "frameworksBody.title5Brief",
	},

	{
		id: "iso27032",
		title: "frameworksBody.title4",
		link: "/frameworks",
		details: "frameworksBody.title4Brief",
	},

	{
		id: "iso9001",
		title: "frameworksBody.title6",
		link: "/frameworks",
		details: "frameworksBody.title6Brief",
	},

	{
		id: 8,
		title: "frameworksBody.title8",
		link: "/frameworks/ndpr",
		details: "frameworksBody.title8Brief",
	},
	{
		id: 'iso22301',
		title: "frameworksBody.title2",
		link: "/frameworks",
		details: "frameworksBody.title2Brief",
	},
];

export const resourcesdata = [
	{
		title: "Blog",
		link: "/blog",
	},
	{
		title: "Faq",
		link: "/faq",
	},
	{
		title: "Glossary",
		link: "/glossary",
	},
];
