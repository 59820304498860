import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ children }) => {
	const { pathname, hash } = useLocation();

	useEffect(() => {
		if (hash) {
			// Scroll to the element with the matching ID
			const target = document.getElementById(hash.substring(1)); // Remove the '#' from hash
			if (target) {
				target.scrollIntoView({ behavior: "smooth", block: "start" });
			}
		} else {
			// Scroll to top for normal navigation without hash
			window.scrollTo({ top: 0, behavior: "smooth" });
		}
	}, [pathname, hash]);

	return children || null;
};

export default ScrollToTop;
