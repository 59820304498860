import React from "react";
import FrameWorkIso27001HeroSectionAnimation from "./FrameWorkIso27001HeroSectionAnimation";

import { useTranslation } from "react-i18next";

const FrameWorkIso27001HeroSection = () => {
	const { t } = useTranslation("isoFramework");
	return (
		<div>
			<div className="py-5 md:py-10 mb-2">
				<div className="container mx-auto flex flex-col space-y-8 md:space-y-0 md:flex-row">
					<div className="w-full md:w-1/2 space-y-4 md:space-y-6">
						<h2 className="text-4xl sm:text-5xl lg:text-[65px] lg:leading-[4.5rem] font-test-sohne-medium">
							{t("isoFrameworkBold")} <br className="hidden md:block" />
							{t("isoFrameworkBold2")}
						</h2>
						<p className="font-test-sohne-light">{t("isoFrameworkLight")}</p>
					</div>
					<FrameWorkIso27001HeroSectionAnimation />
				</div>
			</div>
		</div>
	);
};

export default FrameWorkIso27001HeroSection;
