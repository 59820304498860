import React from "react";
import Navbar from "../connected/Navbar";
import FrameWorksHeroSection from "../sections/FrameWorksSection/FrameWorksHeroSection";
import Footer from "../connected/Footer";
import StillTryingToGetTheHang from "../sections/commonSection/StillTryingToGetTheHang";
import FrameWorksBody from "../sections/FrameWorksSection/FrameWorksBody";
import PageHeader from "../primitive/PageHeader";

const FrameWorkPcidss = () => {
	return (
		<div>
			<PageHeader browserTitle="Frameworks | Smartcomply" />
			<div className="relative pcidss_hero_bg_section_line">
				<div className="absolute top-0 right-0 framework_pcidss_hero_bg w-[65%] h-[100vh] rounded-bl-[226px] -z-20"></div>
				<Navbar />
				<FrameWorksHeroSection />
			</div>
			<FrameWorksBody />
			<StillTryingToGetTheHang />
			<Footer />
		</div>
	);
};

export default FrameWorkPcidss;
