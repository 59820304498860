import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AdditionalFrameworkIcon, ArrowRight, FrameWorkIcon } from "../Icons";
import { frameworkData } from "../../../mockdata/navdropdowndata";
import secure from "../../../assets/icons/secure.svg";
import { ReactComponent as Guitar } from "../../../assets/icons/guitar-head.svg";

const FrameworkDropdown = () => {
  const { t } = useTranslation("header");
  const { t: tl } = useTranslation("frameWorks");

  return (
    <div className="relative z-40 ">
      <div className="group inline-block">
        <button className="outline-none focus:outline-none flex items-center">
          <span>{t("header.frameworks")}</span>
          <span className="pt-1">
            <svg
              className="fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </span>
        </button>
        <div className="absolute transform transition-all scale-0 opacity-0 group-hover:scale-100 group-hover:opacity-100 left-[-30dvw] rounded-lg h-fit px-4 py-6 space-y-3 ease-in-out duration-300 bg-white w-[75dvw] z-40 shadow-[0px_8px_24px_0px_#29292914]">
          <div className="flex w-full gap-5 ">
            <div className="w-[58%] relative">
              <h3 className="text-[#919094] text-sm pl-6">Frameworks</h3>
              <div className="flex gap-4 justify-start">
                <ul className="pt-4 relative w-[48%] flex flex-col gap-2 py-3  origin-top  px-4 pr-0">
                  {frameworkData.slice(0, 5).map((fw) => (
                    <li
                      key={fw.id}
                      className=" hover:bg-[#F1F5F9] w-[47%] transition rounded w-full"
                    >
                      <Link
                        to={
                          fw.link === "/frameworks"
                            ? `${fw.link}#${fw.id}`
                            : fw.link
                        }
                        className="flex gap-3 justify-start items-start w-full p-2"
                      >
                        <FrameWorkIcon className={"w-8 h-8 flex-shrink-0"} />

                        <div className="w-[calc(100%-2rem)]">
                          <h5 className="text-[#475569] text-[16px] font-extrabold uppercase">
                            {tl(fw.title)}
                          </h5>
                          <p className="text-[#64748B] text-[13px] font-thin truncate">
                            {tl(fw.details)}
                          </p>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
                <ul className="pt-4 relative w-[48%] flex flex-col  gap-2 py-3  origin-top  px-4 pr-0">
                  {frameworkData.slice(5, 8).map((fw) => (
                    <li
                      key={fw.id}
                      className=" hover:bg-[#F1F5F9] w-[47%] transition rounded w-full"
                    >
                      <Link
                        to={
                          fw.link === "/frameworks"
                            ? `${fw.link}#${fw.id}`
                            : fw.link
                        }
                        className="flex gap-3 justify-start items-start w-full p-2"
                      >
                        <FrameWorkIcon className={"w-8 h-8 flex-shrink-0"} />

                        <div className="w-[calc(100%-2rem)]">
                          <h5 className="text-[#475569] text-[16px] font-extrabold uppercase">
                            {tl(fw.title)}
                          </h5>
                          <p className="text-[#64748B] text-[13px] font-thin truncate">
                            {tl(fw.details)}
                          </p>
                        </div>
                      </Link>
                    </li>
                  ))}

                  <li className=" hover:bg-[#F1F5F9] w-[47%] transition rounded w-full">
                    <Link to="/frameworks" className="flex gap-3 w-full p-2">
                      <AdditionalFrameworkIcon className={"w-8 h-8"} />

                      <div>
                        <h5 className="text-[#475569] text-[14px] leading-tight font-extrabold ">
                          {tl(`frameworksHeader.addFramework`)}
                        </h5>
                        <p className="text-[#64748B] text-[12px] font-thin">
                          {tl(`frameworksHeader.addFrameworkDetails`)}
                        </p>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="h-72 bg-[#F0F0FA] w-[1px]" />
            <div className="w-[42%] block">
              <div className="block w-full bg-[#F1F5F9] p-7 rounded-[12px]">
                <div className="h-full">
                  <div className="bg-[#F1F5F9] rounded-2xl h-full flex flex-col gap-6">
                    <div className="relative rounded-2xl overflow-hidden bg-white p-6 min-h-[200px] flex justify-center items-center z-[5000]">
                      <img
                        className="absolute w-20 h-auto top-4 left-6"
                        src={secure}
                        alt="secure"
                      />
                      <div className="w-full h-full flex justify-center absolute items-center z-[10]">
                        <div className="rounded-2xl w-16 h-16  rotate-[45deg] bg-[#EEF0FF] " />
                      </div>
                      <Guitar className="absolute -bottom-6 right-0 z-[10] w-28 h-28" />
                      <div className="absolute -bottom-6 blur-[50px] -left-6 w-20 h-20 bg-[linear-gradient(282.97deg,#89A9FC_29.42%,#05DEEC_100%)] rounded-full" />
                      <p className="text-base text-[#46464A] font-bold text-left z-[100] absolute top-14 left-6">
                        Seamless Integrations, Automated Efficiency
                      </p>
                    </div>
                  </div>
                </div>

                <Link
                  to="/frameworks/integration"
                  className="flex items-center gap-3 text-lg text-[#1B2861] mt-4 font-extrabold"
                >
                  <span>{tl("frameworksHeader.integration")}</span>
                  <ArrowRight className="w-7 h-7" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameworkDropdown;
