import React from "react";
import { Dialog } from "primereact/dialog";
import ContentBody from "./ContentBody";
import DataCollectionTable from "./DataCollectionTable";

const PrivacyNoticeModal = (props) => {
  const { open, onClose } = props;
  return (
    <Dialog
      header={
        <div className="flex flex-row items-center gap-3">
          <p>Privacy Notice</p>
        </div>
      }
      visible={open}
      style={{
        width: "707px",
        position: "absolute !important",
        bottom: "1% !important",
        padding: "2rem",
      }}
      onHide={onClose}
      draggable={false}
      headerStyle={{
        borderRadius: "none",
        borderTopLeftRadius: "0 !important",
        borderTopRightRadius: "0 !important",
      }}
    >
      <div>
        <h4 className="text-[12px] font-bold text-[#46464A] tracking-[0.1px] mb-6">
          Last updated: 25 October, 2023
        </h4>
        <ContentBody sx={{ pt: 0 }}>
          This Privacy Notice ("Notice") governs your use of our website:
          https://smartcomplyapp.com/ ("the website) and our webapp:
          https://app.smartcomplyapp.com/ ("the webapp) and your rights
          regarding our collection, use, storage and protection of your data.
          Your privacy is important to us. In collecting some information, we
          are acting as a data controller and processor and, by law, we are
          required to provide you with information about us, about why and how
          we use your data, and about the rights you have over your data.
        </ContentBody>
        <ContentBody title="Privacy notice overview">
          SmartComply Technology Solutions Limited is dedicated to protecting
          the privacy of its merchant and users. As such, we have implemented
          the necessary measures to comply with the Nigeria Data Protection
          Regulation (NDPR), Nigeria Data Protection Act (NDPA), which is
          designed to regulate the collection, processing, and use of personal
          data in Nigeria.
        </ContentBody>
        <ContentBody title="Who we are">
          We are SmartComply Technology Solutions Limited (hereinafter referred
          to as ‘Smartcomply’). Our address is 47, Karimu Ikotun close, Sabo,
          Yaba, Lagos, Nigeria. You can contact us at the above address, by
          email at support@smartcomplyapp.com or by telephone on +234 813 326
          2024.
        </ContentBody>
        <ContentBody title="Personal Information Collected">
          As a user or merchant using the Smartcomply website/web-app, we may
          collect your personal information including your full name, email
          address, phone number, and postal/company address. We may also collect
          your billing and transaction details, as well as demographic
          information. We process your personal information based on the action
          you take, for example, when you create an account, sign up for
          newsletters, complete a form related to one of our services, contact
          us for technical support or customer service, we may also use your
          information for payment processing purposes, or when it is necessary
          to comply with applicable laws.
        </ContentBody>
        <ContentBody title="Cookies">
          Cookies are tools used to automatically collect information from you
          when you visit our Website. When you use our website to browse our
          products and services and view the information, we make available, a
          number of cookies are used by us and by third parties to allow the
          website to function, to collect useful information about visitors and
          to help to make your user experience better. Some of the cookies we
          use are strictly necessary for our website to function, and we do not
          ask for your consent to place these on your computer and information
          about the usage is specified in our Cookie Notice..
        </ContentBody>
        <ContentBody title="Legal bases">
          We are required to process your data under at least one of these
          lawful bases:
          <p className="mb-1">
            {" "}
            <strong> a. Legitimate interest:</strong> Processing your data is
            necessary for our legitimate interests or the legitimate. interests
            of a third party, provided your rights and interests do not outweigh
            those interests.
          </p>
          <p className="mb-1">
            <strong>b. Consent: </strong> You have given explicit consent for us
            to process your data for a specific purpose.
          </p>
          <p className="mb-1">
            <strong>c. Legal obligation: </strong> If the processing of your
            data is necessary where there is a statutory obligation on us.
          </p>
        </ContentBody>
        <ContentBody title="Purpose of processing your data and the lawful bases">
          <p className="mb-3">We collect your data to:</p>
          <DataCollectionTable />
        </ContentBody>
        <ContentBody title="Protection of Personal Information">
          Smartcomply is committed to protecting your personal information
          from unauthorized access, misuse, loss, modification, or disclosure.
          We have implemented appropriate technical, physical, and
          administrative safeguards to ensure the confidentiality, integrity,
          and availability of personal information.
        </ContentBody>
        <ContentBody title="Disclosure of Personal Information">
          <p>
            {" "}
            To protect your privacy is our priority, we limit the sharing of
            your Personal Information outside of Smartcomply. We will not
            share Personal Information about you except under the circumstances
            listed below.{" "}
          </p>
          <p>
            Smartcomply may disclose personal information to its service
            providers and third-party vendors who work on our behalf. The
            service providers we use are obligated by contract to keep your
            personal information private and secure and only use it for its
            intended purposes in compliance with this Notice. It's important to
            note that the data protection laws of the service provider's country
            may not be as stringent as those in your country.
          </p>
          <p>
            By agreeing to this Notice, you acknowledge and authorize these
            third-party service providers to use your personal information. We
            will take all reasonable precautions to ensure your information is
            treated with the utmost security and in compliance with the Privacy
            Notice, and we will not transfer your personal information without
            appropriate measures being taken. Your information is shared or
            disclosed with your permission or based on your direction, for
            instance, if you allow a third-party website client or app to access
            your account.{" "}
          </p>
          <p>
            Smartcomply also reserves the right to disclose your personal
            information where required by law to regulatory, law enforcement, or
            other government authorities, including during routine regulatory
            inspections.
          </p>
        </ContentBody>
        <ContentBody title="Your Rights">
          <p>
            We will like to ensure that our users and merchants are aware of
            their rights.
          </p>
          <p>as data subjects,you have the following data protection rights:</p>
          <p>
            - By law, you can ask us what information we hold about you, and you
            can ask us to correct it if it is inaccurate. - If we have asked for
            your consent to process your personal data, you may withdraw that
            consent at any time.
          </p>{" "}
          <p>
            - If we are processing your personal data for reasons of consent or
            to fulfil a contract, you can ask us to give you a copy of the
            information in a machine-readable format so that you can transfer it
            to another provider.
          </p>{" "}
          <p>
            - If we are processing your personal data for reasons of consent or
            legitimate interest, you can request that your data be erased.{" "}
          </p>
          <p>
            - You have the right to ask us to stop using your information for a
            period of time if you believe we are not doing so lawfully.
          </p>
          <p>
            {" "}
            - Finally, in some circumstances you can ask us not to reach
            decisions affecting you using automated processing or profiling.
          </p>{" "}
          <p>
            To exercise these rights or lodge a complaint, please contact
            Smartcomply's Data Protection Officer.
          </p>
          <p className="mt-3">
            The contact details of our Data Protection Officer are:
          </p>
          <p>Name: Opeyemi Ajakaye-Maku</p>
          <p>Email: ope@smartcomplyapp.com</p>
          <p>
            Post Address: 47, Karimu Ikotun close, Sabo, Yaba, Lagos, Nigeria.{" "}
          </p>
          <p>Phone Number: +234 708 650 1941</p>
        </ContentBody>
        <ContentBody title="Data retention period">
          Your data or any other information collected will be stored for as
          long as necessary to fulfil the purposes described in this Notice.
          However, we will also retain data subject to relevant provisions of
          applicable laws, resolve disputes, and enforce our legal agreements
          and policies.
        </ContentBody>
        <ContentBody title="International transfer of data">
          <p>
            To achieve the purposes described in this Notice, we may transfer
            your data to countries that may not offer an adequate protection
            level or are not considered to have sufficient law by the Nigeria
            Data Protection Regulation (NDPR) and Nigeria Data Protection Act
            (NDPA).
          </p>
          <p>
            Where personal data is to be transferred to a country outside
            Nigeria, we shall put adequate measures to ensure data security.
          </p>
          <p>
            Our data transfers to countries that do not offer an adequate
            protection level are subject to the Nigeria Data Protection
            Regulation (NDPR) conditions. We will therefore only transfer
            Personal Data out of Nigeria on one of the following conditions:
          </p>
          <ul className="ml-3 list-disc">
            <li> Your consent has been obtained;</li>
            <li>
              The transfer is necessary for the performance of a contract
              between us and you or implementation of pre-contractual measures
              taken at your request;
            </li>
            <li>
              The transfer is necessary to conclude a contract between us and
              the third party in your interest;
            </li>
            <li>The transfer is necessary for reason of public interest;</li>
            <li>
              The transfer is for the establishment, exercise or defence of
              legal claims; or
            </li>
            <li>
              The transfer is essential to protect your vital interests or other
              persons, where the data subject is physically or legally incapable
              of giving consent.
            </li>
          </ul>
          <p>
            Please get in touch with us to obtain relevant information regarding
            your data transfers to third countries (including the appropriate
            transfer mechanisms).
          </p>
        </ContentBody>
        <ContentBody title="Complaints">
          <p>
            If you are concerned about an alleged breach of data protection law
            or any other regulation by us, you can contact the{" "}
            <span className="underline">Data Protection Officer (DPO).</span>{" "}
            The DPO will investigate your complaint and provide information
            about how your complaint is handled.
          </p>
          <p>
            Please be informed that you may complain to the relevant data
            protection authority where your complaints are not satisfactorily
            addressed.
          </p>
        </ContentBody>
        <ContentBody title="Changes to Privacy Notice">
          The most current version of this Notice governs our processing of your
          personal data and we may revise this Notice from time to time as
          needed. Smartcomply reserves the right to change its Notice as
          needed for compliance with NDPA & NDPR. Any changes to this Notice
          will be posted on our website, and the effective date will be
          indicated.
        </ContentBody>
        <ContentBody title="Contact Us">
          <p>
            If you have any questions, concerns, or suggestions regarding our
            Privacy Notice, please contact our Data Protection Officer.
          </p>
          <p>You may further contact us via</p>
          <p>Phone: +234 813 326 2024</p>
          <p>Address: 47, Karimu Ikotun close, Sabo, Yaba, Lagos, Nigeria</p>
        </ContentBody>
      </div>
    </Dialog>
  );
};

export default PrivacyNoticeModal;
