import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

const FrameWorkPcidssHeroSectionAnnimation = () => {
	const { t } = useTranslation("frameWorks");
	const [isScaled, setIsScaled] = useState(true);
	const [isSecondScaled, setIsSecondScaled] = useState(true);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setIsScaled((prev) => !prev);
		}, 3000);

		const secondIntervalId = setInterval(() => {
			setIsSecondScaled((prev) => !prev);
		}, 3000);

		return () => {
			clearInterval(intervalId);
			clearInterval(secondIntervalId);
		};
	}, []);

	const scaleClass = isScaled ? "scale-100" : "scale-110";
	const secondScaleClass = isSecondScaled ? "scale-110" : "scale-100";
	return (
		<div className="w-full md:w-1/2 relative pt-64 pb-44 md:pt-0 md:pb-0">
			<div
				className={`w-fit h-fit bg-white rounded-2xl pl-8 py-5 space-y-10 absolute top-[60%] transform -translate-y-1/2 shadow_bg ${scaleClass} transition-all transition_duration`}>
				<div>
					<p className="text-[10px] pr-8">
						{t("frameworksAnimation.smartContract")}
					</p>
					<div className="flex items-start space-x-1 pr-6">
						<span className="w-2 h-2 bg-CaribbeanGreen rounded-full mt-2.5"></span>
						<p className="flex flex-col text-base md:text-xl font-test-sohne-medium text-CharcoalGrey">
							{t("frameworksAnimation.blockchain")}
						</p>
					</div>
				</div>
				<div className="space-y-2">
					<div className="w-full bg-AeroBlue rounded-[4px] py-1 md:py-1.5 px-2 flex items-center justify-between">
						<span className="w-8 md:w-12 h-1 md:h-1.5 rounded-3xl bg-CaribbeanGreen"></span>
						<span className="text-[4px] sm:text-[6px]">
							{t("frameworksAnimation.completed30")}
						</span>
					</div>
					<p className="text-Nobel text-xs">
						{t("frameworksAnimation.answered")}
					</p>
				</div>
			</div>
			<div
				className={`w-fit h-fit bg-white rounded-2xl pl-12 pt-10 pb-5 space-y-6 absolute right-0 md:right-auto top-16 md:top-auto md:left-1/2 md:-transform md:-translate-x-1/4 z-30  shadow_bg ${secondScaleClass} transition-all transition_duration`}>
				<div className="space-y-2">
					<div className="flex items-start space-x-1 pr-1">
						<span className="w-2 h-2 bg-CaribbeanGreen rounded-full mt-2.5"></span>
						<p className="flex flex-col text-base md:text-xl font-test-sohne-medium text-CharcoalGrey">
							{t("frameworksAnimation.iso9001")}
						</p>
					</div>
					<p className="text-[10px] flex flex-col font-test-sohne-light">
						<span>{t("frameworksAnimation.pdcisCompleted")}</span>{" "}
						<span>{t("frameworksAnimation.frameworksUploaded")}</span>
					</p>
				</div>
				<div className="w-full bg-AeroBlue rounded-[4px] py-1 md:py-1.5 px-2 flex items-center space-x-4">
					<span className="w-8 md:w-20 h-1 md:h-1.5 rounded-3xl bg-CaribbeanGreen"></span>
					<span className="text-[4px] sm:text-[6px]">
						{t("frameworksAnimation.completed70")}
					</span>
				</div>
			</div>
			<div
				className={`w-fit h-fit bg-white rounded-2xl pl-8 py-5 space-y-8 absolute right-0 md:right-10 bottom-6 shadow_bg ${scaleClass} transition-all transition_duration`}>
				<div className="space-y-2">
					<p className="text-[10px]">{t("frameworksAnimation.ciiOwners")}</p>
					<div className="flex items-start space-x-1 pr-2">
						<span className="w-2 h-2 bg-CaribbeanGreen rounded-full mt-2.5"></span>
						<p className="flex flex-col text-base md:text-xl font-test-sohne-medium text-CharcoalGrey">
							{t("frameworksAnimation.ciiDirective")}
						</p>
					</div>
				</div>
				<div className="space-y-2">
					<div className="w-full bg-AeroBlue rounded-[4px] py-1 md:py-1.5 px-2 flex items-center justify-between">
						<span className="w-8 md:w-12 h-1 md:h-1.5 rounded-3xl bg-CaribbeanGreen"></span>
						<span className="text-[4px] sm:text-[6px]">
							{t("frameworksAnimation.completed100")}
						</span>
					</div>
					<p className="text-Nobel text-xs">
						{t("frameworksAnimation.answered")}
					</p>
				</div>
			</div>
		</div>
	);
};

export default FrameWorkPcidssHeroSectionAnnimation;
