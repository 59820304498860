import jude from "../assets/team/jude.png";
import valerie from "../assets/team/valerie.png";
import efe from "../assets/team/efe.png";
import anita from "../assets/team/anita.png";
import bola from "../assets/team/bola.png";
import olusola from "../assets/team/sola.png";
import tayo from "../assets/team/tayo.png";
import opeyemi from "../assets/team/ope.png";

export const ourTeamInfo = [
  {
    image: jude,
    name: "Jude Ogbonna",
    title: "Country Head",
    social_medial_name: "Linkedin",
    social_media_link: "https://www.linkedin.com/in/jude-ogbonna-493b2668/",
    bio: ``,
  },
  {
    image: valerie,
    name: "Valerie Azubuike",
    title: "COO/HR",
    social_medial_name: "Linkedin",
    social_media_link: "https://www.linkedin.com/in/valerie-azubuike-phri-/",
    bio: ``,
  },

  {
    image: anita,
    name: "Anita Jallas",
    title: "Engineering Lead",
    social_medial_name: "Linkedin",
    social_media_link: "https://www.linkedin.com/in/amara-ajalla/",
    bio: ``,
  },
  {
    image: efe,
    name: "Efe Ohwonigho",
    title: "Head, Customer Success and Risk Assurance",
    social_medial_name: "Linkedin",
    social_media_link:
      "https://www.linkedin.com/in/efe-ohwonigho-41a585a2/",
    bio: ``,
  },

  {
    image: olusola,
    name: "Olusola Oyekola",
    title: "Head of Products",
    social_medial_name: "Linkedin",
    social_media_link: "https://www.linkedin.com/in/olusola-oyekola-251309152/",
    bio: ``,
  },
  {
    image: bola,
    name: "Bola Lateef Odeshina",
    title: "Platform Engineer",
    social_medial_name: "Linkedin",
    social_media_link:
      "https://www.linkedin.com/in/bola-lateef-odeshina-b084b9268/",
    bio: ``,
  },
  {
    image: tayo,
    name: "Adetokun Adetayo",
    title: "Team Lead",
    social_medial_name: "Linkedin",
    social_media_link:
      "https://www.linkedin.com/in/adetayo-adetokun-9357801bb/",
    bio: `
   `,
  },
  {
    image: opeyemi,
    name: "Ajakaye Opeyemi",
    title: "Head of Compliance",
    social_medial_name: "Linkedin",
    social_media_link: "https://www.linkedin.com/in/opeyemi-ajakaye-maku/",
    bio: ``,
  },
];
