import React from "react";
import { useTranslation } from "react-i18next";

const additionalFrameworks = [
  /* 	{
		id: 1,
		title: "frameworksBody.title1",
		type: "frameworksBody.title1Type",
		details: "frameworksBody.title1Details",
		brief: "frameworksBody.title1Brief",
	}, */
  {
    id: "iso22301",
    title: "frameworksBody.title2",
    type: "frameworksBody.title2Type",
    details: "frameworksBody.title2Details",
    brief: "frameworksBody.title2Brief",
  },
  {
    id: "iso27017",
    title: "frameworksBody.title3",
    type: "frameworksBody.title3Type",
    details: "frameworksBody.title3Details",
    brief: "frameworksBody.title3Brief",
  },
  {
    id: "iso27032",
    title: "frameworksBody.title4",
    type: "frameworksBody.title4Type",
    details: "frameworksBody.title4Details",
    brief: "frameworksBody.title4Brief",
  },
  {
    id: "ciidirectives",
    title: "frameworksBody.title5",
    type: "frameworksBody.title5Type",
    details: "frameworksBody.title5Details",
    brief: "frameworksBody.title5Brief",
  },
  {
    id: "iso9001",
    title: "frameworksBody.title6",
    type: "QA",
    details: "frameworksBody.title6Details",
    brief: "frameworksBody.title6Brief",
  },
  {
    id: "pcislc",
    title: "frameworksBody.title7",
    type: "frameworksBody.title7Type",
    details: "frameworksBody.title7Details",
    brief: "frameworksBody.title7Brief",
  },
  /* 	{
		id: 8,
		title: "frameworksBody.title8",
		type: "frameworksBody.title8Type",
		details: "frameworksBody.title8Details",
		brief: "frameworksBody.title8Brief",
	}, */
  {
    id: "gdpa",
    title: "frameworksBody.title9",
    type: "frameworksBody.title2Type",
    details: "frameworksBody.title9Details",
    brief: "frameworksBody.title9Brief",
  },
  {
    id: "iso37301",
    title: "frameworksBody.title10",
    type: "frameworksBody.title14Type",
    details: "frameworksBody.title10Details",
    brief: "frameworksBody.title10Brief",
  },
  {
    id: "kdpa",
    title: "frameworksBody.title11",
    type: "frameworksBody.title2Type",
    details: "frameworksBody.title11Details",
    brief: "frameworksBody.title11Brief",
  },
  /* 		id: 12,
	{
		title: "frameworksBody.title12",
		type: "frameworksBody.title12Type",
		details: "frameworksBody.title12Details",
		brief: "frameworksBody.title12Brief",
	},
	{
		id: 13,
		title: "frameworksBody.title13",
		type: "frameworksBody.title13Type",
		details: "frameworksBody.title13Details",
		brief: "frameworksBody.title13Brief",
	}, */
];

const FrameWorksBody = () => {
  const { t } = useTranslation("frameWorks");
  return (
    <div>
      <div className="container mx-auto flex justify-end mt-[10rem]">
        <div className="w-[100%]">
          <h2 className="w-full p-0 m-0 text-Ebony text-[26px] font-semibold">
            {t("frameworksBody.intro")}
          </h2>

          <div
            className="grid my-10"
            style={{
              gridTemplateColumns: "repeat(auto-fit, minmax(20rem, 1fr))",
            }}
          >
            {additionalFrameworks?.map((adFw) => {
              return (
                <div
                  key={adFw.id}
                  id={adFw.id}
                  className="border-b-[1px] pr-5 pt-10 border-[#FFC87F]"
                >
                  <div className="flex gap-5">
                    <span
                      className="uppercase border-[1px] w-24 px-2 py-1 text-[12px] h-fit font-semibold border-[#FFC87F] bg-[#FFFAF3] rounded-[5px]"
                      style={{}}
                    >
                      {t(adFw["type"])}
                    </span>

                    <h4 className="text-lg font-semibold">
                      {t(adFw["title"])}
                    </h4>
                  </div>
                  <p className="text-Boulder my-10">
                    {t(adFw["brief"])}: <br />
                    {t(adFw["details"])}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameWorksBody;
