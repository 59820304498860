export const FrameWorkIcon = (props) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect width="24" height="24" rx="8" fill="#395BA9" />
		<path
			d="M12 13C12.5304 13 13.0391 12.7893 13.4142 12.4142C13.7893 12.0391 14 11.5304 14 11C14 10.4696 13.7893 9.96086 13.4142 9.58579C13.0391 9.21071 12.5304 9 12 9C11.4696 9 10.9609 9.21071 10.5858 9.58579C10.2107 9.96086 10 10.4696 10 11C10 11.5304 10.2107 12.0391 10.5858 12.4142C10.9609 12.7893 11.4696 13 12 13Z"
			stroke="#FFC87F"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13.86 6.5H14.75C15.72 6.5 16.5 7.28 16.5 8.25V9.15M16.5 12.87V13.75C16.5 14.72 15.72 15.5 14.75 15.5M9.25 15.5C8.28 15.5 7.5 14.72 7.5 13.75V12.87M7.5 9.14V8.25C7.5 7.28 8.28 6.5 9.25 6.5H10.14"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16.5 9.13999L17.13 9.75999C17.81 10.45 17.81 11.56 17.13 12.24L16.49 12.87M14.5 15.5V19L12 17L9.49999 19V15.5M7.49999 12.86L6.86999 12.24C6.70672 12.0774 6.57716 11.8841 6.48876 11.6714C6.40036 11.4586 6.35486 11.2304 6.35486 11C6.35486 10.7696 6.40036 10.5414 6.48876 10.3286C6.57716 10.1158 6.70672 9.92258 6.86999 9.75999L7.49999 9.13999M10.13 6.49999L10.76 5.86999C11.45 5.18999 12.56 5.18999 13.24 5.86999L13.86 6.49999"
			stroke="white"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);
export const AdditionalFrameworkIcon = (props) => {
	return (
		<svg
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect width="24" height="24" rx="8" fill="#395BA9" />
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M8.49996 12C8.49996 12.1532 8.46978 12.3049 8.41115 12.4464C8.35252 12.588 8.26659 12.7166 8.15825 12.8249C8.04992 12.9333 7.9213 13.0192 7.77976 13.0778C7.63821 13.1365 7.4865 13.1666 7.33329 13.1666C7.18008 13.1666 7.02838 13.1365 6.88683 13.0778C6.74528 13.0192 6.61667 12.9333 6.50833 12.8249C6.4 12.7166 6.31406 12.588 6.25543 12.4464C6.1968 12.3049 6.16663 12.1532 6.16663 12C6.16663 11.6906 6.28954 11.3938 6.50833 11.175C6.72713 10.9562 7.02387 10.8333 7.33329 10.8333C7.64271 10.8333 7.93946 10.9562 8.15825 11.175C8.37704 11.3938 8.49996 11.6906 8.49996 12ZM12 10.8333C12.3094 10.8333 12.6061 10.9562 12.8249 11.175C13.0437 11.3938 13.1666 11.6906 13.1666 12C13.1666 12.3094 13.0437 12.6061 12.8249 12.8249C12.6061 13.0437 12.3094 13.1666 12 13.1666C11.6905 13.1666 11.3938 13.0437 11.175 12.8249C10.9562 12.6061 10.8333 12.3094 10.8333 12C10.8333 11.6906 10.9562 11.3938 11.175 11.175C11.3938 10.9562 11.6905 10.8333 12 10.8333ZM16.6666 10.8333C16.976 10.8333 17.2728 10.9562 17.4916 11.175C17.7104 11.3938 17.8333 11.6906 17.8333 12C17.8333 12.3094 17.7104 12.6061 17.4916 12.8249C17.2728 13.0437 16.976 13.1666 16.6666 13.1666C16.3572 13.1666 16.0605 13.0437 15.8417 12.8249C15.6229 12.6061 15.5 12.3094 15.5 12C15.5 11.6906 15.6229 11.3938 15.8417 11.175C16.0605 10.9562 16.3572 10.8333 16.6666 10.8333Z"
				fill="white"
			/>
		</svg>
	);
};

export const ArrowRight = (props) => {
	return (
		<svg
			{...props}
			width="20"
			height="21"
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.3363 10.7673H5.41964M16.6696 10.7673L11.6696 5.76727M16.6696 10.7673L11.6696 15.7673M16.6696 10.7673H7.91964"
				stroke="#1B2861"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
