import React from "react";
import linkdin_icon from "../../../assets/icons/linkdin_icon.svg";
import { Link } from "react-router-dom";

const OurTeamCard = ({ image, name, title, social_media_link }) => {
  return (
    <div className="w-full h-full md:w-60 bg-BlueChalk  rounded-3xl h-[374px] transition-all duration-500">
      <div className="w-full relative h-64 max-sm:h-72 md:h-52 border border-white rounded-3xl">
        {!image ? (
          <span className="w-full h-full bg-red-200"></span>
        ) : (
          <img
            src={image}
            className="w-full grayscale h-full object-cover rounded-3xl"
            alt={name}
            loading="eager"
          />
        )}
        <div className="absolute top-0 h-full w-full bg-[linear-gradient(180deg,rgba(240,229,255,0)_78.26%,#F0E5FF_100%)]" />
      </div>
      <div className="w-full bg-BlueChalk rounded-b-3xl px-6 py-4 flex flex-col gap-7 items-start justify-start">
        <p
          className={`text-lg text-[#060606] font-test-sohne-semibold`}
        >
          {name}
        </p>
        <p className="text-[#060606] text-[15px]">
          {title}
        </p>
        <div className="w-fit py-1 bg-white rounded-xl flex items-center space-x-2 pl-1 pr-3">
          <img src={linkdin_icon} className="" alt="" />
          <Link
            to={social_media_link}
            target="_blank"
            className="text-[13px] text-[#414141]"
          >
            <span>Follow on</span>{" "}
            <span className="font-test-sohne-medium ">Linkedin</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OurTeamCard;
